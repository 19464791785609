import { computed, reactive } from 'vue';
import i18n from '@/i18n';
import toastr from 'toastr';
import { ApiResourceInterface, IModel } from '@/resources/ApiResource';
import { AxiosResponse } from 'axios';
import { forOwn } from 'lodash';

const { t } = i18n.global;

interface IFormErrors {
    message: string;
    [key: string]: string;
}

interface IErrorResponse {
    message: string;
    errors: {
        [key: string]: string[];
    }
}
export default function useForm(model: IModel, resource: ApiResourceInterface) {
    const state = reactive({
        model,
        errors: <IFormErrors>{},
        isSaving: false
    });

    const parseErrors = (errors: IErrorResponse): void => {
        Object.assign(state.errors, { message: errors.message });

        forOwn(errors.errors, (value, key) => {
            Object.assign(state.errors, { [key]: t(value[0]) });
        });
    };

    const clearErrors = (): void => {
        forOwn(state.errors, (value, key) => {
            delete state.errors[key];
        });
    };

    const isSaving = computed(() => {
        return state.isSaving
    });

    const update = (): Promise<AxiosResponse> => {
        clearErrors();
        state.isSaving = true;

        return resource.update(state.model).then((response) => {
            toastr.success(t('general.UPDATED'), '', { timeOut: 2000 });

            return Promise.resolve(response);
        }).catch((error) => {
            parseErrors(error.response.data);

            return Promise.reject(error);
        }).finally(() => {
            state.isSaving = false;
        });
    };

    const save = (): Promise<AxiosResponse> => {
        clearErrors();
        state.isSaving = true;

        return resource.save(state.model).then((response) => {
            toastr.success(t('general.SAVED'), '', { timeOut: 2000 });

            return Promise.resolve(response);
        }).catch((error) => {
            parseErrors(error.response.data);

            return Promise.reject(error);
        }).finally(() => {
            state.isSaving = false;
        });
    };

    return {
        state,
        isSaving,
        update,
        save,
    };
}
